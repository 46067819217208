@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

a {
  @apply font-bold text-breeze-600 hover:opacity-80;
}

ol {
  @apply list-decimal;
}

ul {
  @apply list-disc;
}

li {
  @apply ml-7;
}

button,
.button {
  @apply cursor-pointer hover:opacity-80;
}

button[disabled],
.button[disabled] {
  @apply opacity-30 hover:opacity-30;
}

table {
  @apply relative;
}

table::before {
  content: '';
  @apply w-full h-full absolute top-0 right-0 border-2 rounded-md;
}

th, td {
  @apply border-l-2 border-t-2 p-4 align-top;
}

table > :first-child > tr:first-child > * {
  @apply border-t-0;
}

td:first-child, th:first-child {
  @apply border-l-0;
}

.gradient-bg {
  background: linear-gradient(180deg, rgba(39,89,116,1) 0%, rgba(9,42,61,1) 100%);
}
